<template>
<div v-if="reloadForm == false">
    <div v-if="itemCreate">
        <Breadcrumb :home="home" :model="items" class="mb-3"/>
        <div class="grid">
            <div class="col-12">
                <div class="card">
                    <Toast/>
                    <Error :errors="errors"/>

                    <Message severity="info" :closable="false">
                        <div>Project Code: {{this.itemCreate?.project_code}}</div>
                        <div>Project Name: {{this.itemCreate?.project_name}}</div>
                    </Message>


                    <Fieldset legend="Import" :toggleable="true" :collapsed="true" class="mb-3">
                        <Import :id="itemCreate.project_id" @submit="getDataTable()" ref="import"/>
                    </Fieldset>

                    <DataTable :value="dataTable" :resizableColumns="true" columnResizeMode="fit" responsiveLayout="scroll" :loading="loading" dataKey="number" @sort="onSort($event)">
                        <template #header>
                            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                <div>
                                    <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')"/>
                                    <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')"/>
                                </div>
                                <span class="block mt-2 md:mt-0 p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText v-model="search" placeholder="Search..." @keyup.enter="getDataTable" />
                                </span>
                            </div>
                        </template>
                        <Column field="number" header="#" :style="{width:'20px'}" >
                            <template #body="slotProps">
                            {{ slotProps.data.number }}
                            </template>
                        </Column>
                        <Column field="project_code" header="Project Code">
                            <template #body="slotProps">
                                {{slotProps.data.project_code}}
                            </template>
                        </Column>
                        <Column field="project_name" header="Project Name">
                            <template #body="slotProps">
                                {{slotProps.data.project_name}}
                            </template>
                        </Column>
                        <Column field="industry_name" header="Industry">
                            <template #body="slotProps">
                                {{slotProps.data.industry_name}}
                            </template>
                        </Column>
                        <Column field="channel_name" header="Channel">
                            <template #body="slotProps">
                                {{slotProps.data.channel_name}}
                            </template>
                        </Column>
                        <Column field="code_by_user" header="Outlet Type Code">
                            <template #body="slotProps">
                                {{slotProps.data.code_by_user}}
                            </template>
                        </Column>
                        <Column field="name_by_user" header="Outlet Type Name">
                            <template #body="slotProps">
                                {{slotProps.data.name_by_user}}
                            </template>
                        </Column>
                        <Column field="frequency_of_call" header="Frequency Of Call">
                            <template #body="slotProps">
                                {{slotProps.data.frequency_of_call}}
                            </template>
                        </Column>
                        <Column field="outlet_code" header="Outlet Code">
                            <template #body="slotProps">
                                {{slotProps.data.outlet_code}}
                            </template>
                        </Column>
                        <Column field="outlet_name" header="Outlet Name">
                            <template #body="slotProps">
                                {{slotProps.data.outlet_name}}
                            </template>
                        </Column>
                        <Column field="outlet_latitude" header="Latitude">
                            <template #body="slotProps">
                                {{slotProps.data.outlet_latitude}}
                            </template>
                        </Column>
                        <Column field="outlet_longitude" header="Longitude">
                            <template #body="slotProps">
                                {{slotProps.data.outlet_longitude}}
                            </template>
                        </Column>
                        <Column field="outlet_coordinate" header="Coordinate">
                            <template #body="slotProps">
                                {{slotProps.data.outlet_coordinate}}
                            </template>
                        </Column>
                        <Column field="outlet_sales_volume" header="Avg Sales">
                            <template #body="slotProps">
                                {{slotProps.data.outlet_sales_volume}}
                            </template>
                        </Column>
                        <Column field="created_at" header="Created At">
                            <template #body="slotProps">
                                {{slotProps.data.created_at}}
                            </template>
                        </Column>
                        <Column field="userweb_name" header="Created By">
                            <template #body="slotProps">
                                {{slotProps.data.userweb_name}}
                            </template>
                        </Column>
                        <template #empty>
                            No data found.
                        </template>
                        <template #loading>
                            Loading data. Please wait.
                        </template>
                        <template #footer>
                            In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                        </template>
                    </DataTable>

                    <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
                        <template>
                            {{totalItemsCount}}
                        </template>
                    </Paginator>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <h3 class="text-center">Project Not Found</h3>
    </div>
</div>
<div v-else>
    <SkeletonComponent />
</div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../../../utils/helpers';
import Import from './Import.vue';
import Error from '../../../Error.vue';
import SkeletonComponent from '../../../SkeletonComponent.vue';

export default {
    components: {
		'Import': Import,
		'Error': Error,
		'SkeletonComponent': SkeletonComponent,
	},
    data() {
        return {
            // breadcrumb
            home: {icon: 'pi pi-home', to: '/'},
            items: [
                {label: 'Project', to: '/project'},
                {label: 'Outlet'},
            ],

            // loading
            loading: false,
            loadingExcel: false,
            loadingCsv: false,
            reloadForm: false,

            project_id: null,

            itemCreate: {},

            // edit,delete
            item: {},

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    created() {
        this.project_id = this.$route.params.id;
        this.getItemById(this.project_id);
    },
    mounted() {
        this.$store.commit('setErrors', {});
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        }
    },
    computed:{
        ...mapGetters(['errors']),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            // this.$refs.filter.loading = true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/trans-outlet',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "project_id" : this.project_id,
                }
            })
            .then(res => {
                this.data = res.data.data.data;
                this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
                this.totalItemsCount = res.data.data.total;
                this.rows = parseInt(res.data.data.per_page);
                this.loading=false;
                // this.$refs.filter.loading = false;
            })
            .catch((err) => {
                console.log(err);
                this.loading=false;
                // this.$refs.filter.loading = false;
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext){

            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
            method: 'GET',
            url: process.env.VUE_APP_ROOT_API + 'web/trans-outlet/export',
            responseType: 'blob',
            params: {
                "ext" : ext,
                "project_id" : this.project_id,
                // "industry_id" : this.$refs.filter.filters.industry_id,
            }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Outlet Export.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
        getItemById(id) {
            this.reloadForm = true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/project/check',
                params: {
                    "project_id": id,
                }
            })
            .then(res => {
                this.reloadForm = false;

                if (res) {

                    this.$store.commit('setErrors', {});
                    this.itemCreate = res.data.data;

                    setTimeout(() => {
                        this.getDataTable();
                    }, 200);

                }
            }, (this))
            .catch((err) => {
                this.itemCreate = null;
                console.log(err);
                this.reloadForm = false;
            });
        }
    }
}
</script>