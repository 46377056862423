<template>
    <div class="p-fluid mb-3">       
        <div class="grid">
            <div class="lg:col-12 md:col-12 sm:col-12 align-items-center justify-content-center">
                <h5>Guide</h5>                    
                <table>
                    <thead>
                        <tr>
                            <th>Field Name</th>
                            <th>Description</th>
                            <th>Additional Instructions/Notes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>OUTLET CODE</td>
                            <td>A unique code to identify the outlet.</td>
                            <td>
                                - Maximum 20 characters<br>
                                - Use letters, numbers, or hyphens. Avoid spaces and special characters.<br>
                                - Make it descriptive and easy to remember (e.g., "JKT-MAIN" for the main outlet in Jakarta).
                            </td>
                        </tr>
                        <tr>
                            <td>OUTLET NAME</td>
                            <td>The full name of the outlet.</td>
                            <td>- Maximum 100 characters</td>
                        </tr>
                        <tr>
                            <td>ADDRESS</td>
                            <td>The complete physical address of the outlet.</td>
                            <td>- Include street name, number, city, state/province, and postal code.</td>
                        </tr>
                        <tr>
                            <td>LATITUDE</td>
                            <td>The geographic latitude coordinate of the outlet.</td>
                            <td>- Use decimal degrees format (e.g., -6.2088).</td>
                        </tr>
                        <tr>
                            <td>LONGITUDE</td>
                            <td>The geographic longitude coordinate of the outlet.</td>
                            <td>- Use decimal degrees format (e.g., 106.8456).</td>
                        </tr>
                        <tr>
                            <td>OUTLET TYPE CODE - USER DEFINITION</td>
                            <td>A code representing the outlet's type.</td>
                            <td>
                                - Refer to the <i><a style="cursor: pointer;" @click="directTo">"Outlet Type Code - User Definition"</a></i> link for the list of valid codes.<br>
                                - Ensure the code you enter matches the outlet's actual type.
                            </td>
                        </tr>
                        <tr>
                            <td>SALES</td>
                            <td>The average monthly sales of the outlet.</td>
                            <td>
                                - Enter the amount in your local currency.<br>
                                - If you don't have exact figures, provide the best estimate possible.
                            </td>
                        </tr>
                    </tbody>
                </table>
                <ol>
                    <li>
                        <p class="line-height-3 m-0">Please download the template first <a target="_blank" href="https://docs.google.com/spreadsheets/d/1XI-ZtObl62dY7RDZJQvJ67l9i4icJgm2/edit?gid=242450233#gid=242450233"><u><i>HERE</i></u></a> </p>
                    </li>
                    <li>
                        <p class="line-height-3 m-0">Ensure that the uploaded file is in Excel or CSV format.</p>
                    </li>
                    <li>
                        <p class="line-height-3 m-0">Make sure the file size does not exceed 2MB.</p>
                    </li>
                    <li>
                        <p class="line-height-3 m-0">Ensure that the columns are filled in correctly.</p>
                    </li>
                </ol>
                <div class="line-height-3 m-0" style="color:red;">Note: Please follow the step-by-step instructions carefully to ensure a successful data upload.</div>
            </div>
            <div class="sm:col-12 md:col-12 lg:col-6">
                <BlockUI :blocked="blockPanel">
                    <form>
                        <div class="p-fluid">
                            <div class="formgrid grid">
                                <div class="field col-12 md:col-12 lg:col-6">
                                    <!-- FORM -->
                                </div>
                            </div>
                        </div>
                        <FileUpload ref="fileUpload" name="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" chooseLabel="Choose File" uploadLabel="Upload" cancelLabel="Cancel" :customUpload="true" @uploader="onUpload" :fileLimit="1" :maxFileSize="2000000">
                        <template #empty>
                            <p>Drag and drop the file here to upload.</p>
                            <ProgressBar mode="indeterminate" style="height: .5em" v-if="blockPanel"/>
                        </template>
                        </FileUpload>
                    </form>
                </BlockUI>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
    emits: ['submit'], 
    props: ['id'],
    data() {
        return {
            // loading
            loadingDownloadTemplate: false,

            // upload
            blockPanel: false,
            file: null,
        }
    },
    mounted() {
        this.$store.commit('setErrors', {});
    },
    computed: {
        ...mapGetters(['errors']),
    },
    methods: {
        directTo(){
            this.$router.replace({ name: "project-outlet-type", params: {id:this.id} })
        },
        //UPLOAD
        onUpload(event) {

            this.$store.commit("setErrors", {});
            this.blockPanel = true;
            this.file = event.files[0];

            let data = new FormData();
            data.append('file', this.file);
            data.append('project_id', this.id);

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/trans-outlet/import',
                data: data,
                onUploadProgress: function (e) {
                     this.$refs.fileUpload.progress = parseInt(Math.round((e.loaded * 100) / e.total));
                }.bind(this)
            })
			.then(res => {
                console.log(res);
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000});
                this.$store.commit("setErrors", {});
                this.$refs.fileUpload.uploadedFileCount = 0;
                this.$refs.fileUpload.progress = null;
                this.blockPanel = false;

                this.clearForms();

                this.$emit('submit');
			})
            .catch((err) => {
                console.log(err);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Saved', life: 3000,});
                this.$refs.fileUpload.uploadedFileCount = 0;
                this.$refs.fileUpload.progress = null;
                // this.$refs.message.visible = true;
                this.blockPanel = false;
            });
        },
        clearForms() {
            this.file = null;
        },
    }
}
</script>

<style>
    table {
        width: 100%;
        border-collapse: collapse;
    }
    th, td {
        border: 1px solid #ddd;
        padding: 8px;
    }
    th {
        background-color: #f2f2f2;
        text-align: left;
    }
</style>